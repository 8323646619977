<template>
  <div id="app" :style="{ backgroundImage: `url(${backgroundImages[currentBackground]})` }">
    <div class="map-selector">
      <el-select v-model="currentBackground" @change="selectBackground">
        <el-option
            v-for="(name, index) in mapNames"
            :key="index"
            :label="name"
            :value="index"
        ></el-option>
      </el-select>
    </div>
    <div class="trajectory-container">
      <svg :width="viewportWidth" :height="viewportHeight">
        <path v-if="trajectory.length > 1" :d="trajectoryPath" stroke="blue" fill="none"/>
        <circle v-for="(point, index) in trajectory" :key="index" :cx="point.x" :cy="point.y" r="5" fill="red"/>
      </svg>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Trajectory',
  data() {
    return {
      trajectory: [],
      viewportWidth: 800,
      viewportHeight: 600,
      currentBackground: 0, // 当前背景图片的索引
      backgroundImages: [
        require('@/assets/hys.png'), // 引入第一张图片
        require('@/assets/tcc.png'), // 引入第二张图片
        require('@/assets/biaoji.png'), // 引入第三张图片
      ],
      mapNames: ['会议室', '停车场', '标记图'], // 自定义地图名称
    };
  },
  computed: {
    uplinkData() {
      return this.$store.state.uplinkData.filter(data => data.command === 'UpLink');
    },
    trajectoryPath() {
      return this.trajectory.map((p, i, a) =>
          `${i === 0 ? `M${p.x},${p.y}` : `L${p.x},${p.y}`}`
      ).join('');
    },
  },
  watch: {
    uplinkData(newData) {
      newData.forEach(data => {
        const { x, y } = data;
        const newX = x * (this.viewportWidth / 10);
        const newY = this.viewportHeight - (y * (this.viewportHeight / 5));
        this.trajectory.push({ x: newX, y: newY });
        if (this.trajectory.length > 10) this.trajectory.shift();
      });
    },
  },
  created() {

  },
  beforeDestroy() {

  },
  methods: {
    selectBackground(value) {
      // 使用 value 直接更新 currentBackground
      this.currentBackground = value;
    },
  },
};
</script>

<style scoped>
#app {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-size: contain;
  background-position: left;
  background-repeat: no-repeat;
  height: 100vh;
}

.trajectory-container {
  width: 100%;
  height: 400px;
}

.map-selector {
  position: absolute;
  top: 70px;
  left: 500px;
  z-index: 1000;
}
</style>
